body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background: #eeeefe !important;
  color: #333 !important;
  background-attachment: fixed !important;
  text-align: center !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.breadcrumb { 
  list-style: none; 
  overflow: hidden; 
  font: 18px Helvetica, Arial, Sans-Serif;
  padding: 0;
}
.breadcrumb li { 
  float: left; 
}
.breadcrumb li a {
  color: white;
  text-decoration: none; 
  padding: 10px 0 10px 55px;
  background: brown; /* fallback color */
  background: hsla(34,85%,35%,1); 
  position: relative; 
  display: block;
  float: left;
}
.breadcrumb li a:after { 
  content: " "; 
  display: block; 
  width: 0; 
  height: 0;
  border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid hsla(34,85%,35%,1);
  position: absolute;
  top: 50%;
  margin-top: -50px; 
  left: 100%;
  z-index: 2; 
}   

.breadcrumb li a:before { 
  content: " "; 
  display: block; 
  width: 0; 
  height: 0;
  border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px; 
  margin-left: 1px;
  left: 100%;
  z-index: 1; 
}   
.breadcrumb li:first-child a {
  padding-left: 10px;
}
.breadcrumb li:nth-child(2) a       { background:        hsla(34,85%,45%,1); }
.breadcrumb li:nth-child(2) a:after { border-left-color: hsla(34,85%,45%,1); }
.breadcrumb li:nth-child(3) a       { background:        hsla(34,85%,55%,1); }
.breadcrumb li:nth-child(3) a:after { border-left-color: hsla(34,85%,55%,1); }
.breadcrumb li:nth-child(4) a       { background:        hsla(34,85%,65%,1); }
.breadcrumb li:nth-child(4) a:after { border-left-color: hsla(34,85%,65%,1); }
.breadcrumb li:nth-child(5) a       { background:        hsla(34,85%,75%,1); }
.breadcrumb li:nth-child(5) a:after { border-left-color: hsla(34,85%,75%,1); }
.breadcrumb li:last-child a {
  background: #fff;
  color: black;
  pointer-events: none;
  cursor: default;
}
.breadcrumb li:last-child a:after { border: 0; }
.breadcrumb li a:hover { background: hsla(34,85%,25%,1); }
.breadcrumb li a:hover:after { border-left-color: hsla(34,85%,25%,1) !important; }

div#container {
  width: 750px;
  margin: 0 auto;
  padding: 10px 0;
  text-align: left;
}

div#header {
  margin: 10px auto;
  height: 80px;
  width: 750px;
  text-align: left;
  background: #C42f1e url('gids/newbg.png');
  color: #FFF;
}
div#content {
  float: left;
  width: 590px;
  padding: 10px;
  background: #ffffff;
  margin-bottom: 10px;
  min-height: 550px;

}
div#sider {
  float: right;
  width: 155px;
  padding: 0;
}

div#nav {
  background: #e2c7f0 url('gids/paars.jpg') repeat-x 0 -5px;
  width: 150px;
  padding: 10px 0;
  margin-bottom: 10px;
}



div#vav {
  background: #c8d3f1 url('gids/blauw.jpg') repeat-x 0 -5px;
  width: 150px;
  padding: 10px 0;
  margin-bottom: 10px;
}

div#zoek{width: 310px;background:fbd603;position: relative; top: 0px; left:440px}
div#zoek input#find{width: 145px; background:transparent url('gids/searchbg.png');border:0px solid black;height:40px; font-family: 'kentekenregular'; font-size:28px;}
div#zoek button{ 
  position:absolute !important; 
  left:170px !important; 
  top:3px !important
}